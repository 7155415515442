@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+Warang+Citi&family=Train+One&display=swap");

.line2 {
  width: 120px;
  height: 2px;
  background: rgba(0, 0, 0, 0.596);
}

.train {
  font-family: "Train One", system-ui;
  font-weight: 400;
  font-style: normal;
}

model-viewer {
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
/* #ar-button{
  background-color: antiquewhite;
} */
.error {
  color: #ffa3a3;
  font-weight: bold;
  font-size: 11px;
  margin-top: 10px;
}

.css-q1x83w {
  height: auto !important;
}
.carousalnews {
  height: 400px;
}

.wrappernews {
  overflow: hidden;
  width: 80%;
  margin-top: 5%;
  width: 100%;
  height: 400px;
  text-align: center;
}
/* fleker */

.sp-container h2.frame-5 {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
  color: transparent;
  flex-direction: column;
  display: flex;
  /* text-shadow: 0px 0px 1px #fff; */
}
.sp-container h2.frame-5 span {
  -webkit-animation: blurFadeIn 1s ease-in 1s backwards;
  -moz-animation: blurFadeIn 1s ease-in 1s backwards;
  -ms-animation: blurFadeIn 1s ease-in 1s backwards;
  animation: blurFadeIn 1s ease-in 1s backwards;
  /* color: transparent; */
  /* text-shadow: 0px 0px 1px #fff; */
}
.sp-container h2.frame-5 span:nth-child(2) {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -ms-animation-delay: 2s;
  animation-delay: 2s;
}

/**/

@-webkit-keyframes blurFadeInOut {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    -webkit-transform: scale(1.3);
  }
  20%,
  75% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    -webkit-transform: scale(1);
  }
  100% {
    opacity: 0;
    text-shadow: 0px 0px 50px #fff;
    -webkit-transform: scale(0);
  }
}
@-webkit-keyframes blurFadeIn {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    -webkit-transform: scale(1.3);
  }
  50% {
    opacity: 0.5;
    text-shadow: 0px 0px 10px #fff;
    -webkit-transform: scale(1.1);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    -webkit-transform: scale(1);
  }
}
@-webkit-keyframes fadeInBack {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
  }
  50% {
    opacity: 0.4;
    -webkit-transform: scale(2);
  }
  100% {
    opacity: 0.2;
    -webkit-transform: scale(5);
  }
}

/**/

@-moz-keyframes blurFadeInOut {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    -moz-transform: scale(1.3);
  }
  20%,
  75% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    -moz-transform: scale(1);
  }
  100% {
    opacity: 0;
    text-shadow: 0px 0px 50px #fff;
    -moz-transform: scale(0);
  }
}
@-moz-keyframes blurFadeIn {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    -moz-transform: scale(1.3);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    -moz-transform: scale(1);
  }
}
@-moz-keyframes fadeInBack {
  0% {
    opacity: 0;
    -moz-transform: scale(0);
  }
  50% {
    opacity: 0.4;
    -moz-transform: scale(2);
  }
  100% {
    opacity: 0.2;
    -moz-transform: scale(5);
  }
}

/**/

@keyframes blurFadeInOut {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    transform: scale(1.3);
  }
  20%,
  75% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    text-shadow: 0px 0px 50px #fff;
    transform: scale(0);
  }
}
@keyframes blurFadeIn {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 40px #fff;
    transform: scale(1.3);
  }
  50% {
    opacity: 0.5;
    text-shadow: 0px 0px 10px #fff;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 0px 1px #fff;
    transform: scale(1);
  }
}
@keyframes fadeInBack {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.4;
    transform: scale(2);
  }
  100% {
    opacity: 0.2;
    transform: scale(5);
  }
}
::-webkit-scrollbar {
  width: 8px;
}
 
::-webkit-scrollbar-track {
  background: transparent;
}
 
::-webkit-scrollbar-thumb {
  background: #a8d4ff;
  border-radius: 4px;
}
 
::-webkit-scrollbar-thumb:hover {
  background: #7ab8ff;
}
.markdown-content a {
  color: rgb(147, 224, 255);
  text-decoration: underline;
}
 
.markdown-content a:hover {
  color: rgb(250, 158, 233);
}


.markdown-content ul {
  padding-left: 20px;
  list-style-type: square; /* Custom bullet style */
}

.markdown-content li {
  margin-bottom: 5px;
  font-size: 16px;
}