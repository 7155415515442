.paginationBttns{
    display: flex;
    font-size: 10px;
   
    height: 40px;
}
.paginationBttns a{
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    color: rgb(129, 133, 133);
    
}
.paginationBttns a:hover{
    color: rgb(90, 96, 96);
    background-color:rgb(206, 214, 220)  ;
}
.paginationActive a{
    background-color:rgb(198, 227, 252) ;
}