@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100&family=Roboto:wght@100&family=Sarpanch&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");


@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');




.content_font {
  font-family: "Nunito", sans-serif;

 
}
.heading {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bolder;
}
.blogprofile{
  font-family: 'Poiret One', cursive;
}


.slogan {
  color: #fff;
  text-align: center;
  text-align: center;
 
}


/* floating animation */
.floatinganime{
  animation: 3s infinite alternate floating;
}
@keyframes floating {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-6px);
	}
	100% {
		transform: translatey(0px);
	}
}
.shadowanime{
  height:2px;
  display:block;
  margin:15px auto;
  animation: 3s infinite alternate shadow;
}
@keyframes shadow {
	0% {
    width:40px;
		box-shadow:0px 10px 8px rgba(0,0,0,.5);
	}
	50% {
    width:20px;
		box-shadow:0px 10px 8px rgba(0,0,0,.3);
	}
	100% {
    width:40px;
		box-shadow:0px 10px 8px rgba(0,0,0,.5);
	}
}
/* floating animation */


.clientimage{
  width: 100px;
  
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  
}
.clientlist{
  height: 250px;
  
}
.clientbox{
  -moz-box-shadow:    inset 0 0 5px #9d9d9d;
  -webkit-box-shadow: inset 0 0 5px #9d9d9d;
  box-shadow:         inset 0 0 5px #9d9d9d;
  width: 200px;
  border-radius: 10px;
 background-color: #fff;
 align-items: center;
 justify-content: center;
}
/* client section */
/* .NavHov:hover{
  text-decoration: none;
} */
.NavActive{
  /* border: 1px solid #01B1D8; */
  background-color: #0B091E;

  border-radius: 5px;
  padding: 4px;
  box-shadow: 0px 0px 8.2px 0px #01B1D8;
 
  
}
.decor{
  text-decoration: underline;
  margin-left: 5px;
}
.decorfooter :hover{
  text-decoration: underline;

}

.navbar {
  /* margin-top: 500px; */
  /* background-image: url("https://res.cloudinary.com/trackgenesis/image/upload/v1653625168/TG-Website/images/Navbar_4_yv4cuc.png"); */
  background: linear-gradient(
    180deg,
    rgba(5, 71, 170, 0.66) 0%,
    rgba(7, 76, 179, 0.6002) 18.75%,
    rgba(5, 77, 185, 0.5726) 30.21%,
    rgba(13, 83, 189, 0.4404) 40.1%,
    rgba(7, 75, 176, 0.376) 57.81%,
    rgba(5, 78, 187, 0.2576) 72.4%,
    rgba(7, 85, 202, 0.184) 84.9%,
    rgba(4, 82, 199, 0) 100%
  );
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.popup {
  border: 1px solid #01B1D8 !important;
  background: linear-gradient(
    180deg,
    #0B091E 0%,
    #0B091E 18.75%,
    #0B091E 30.21%,
    #0B091E 40.1%,
    #0B091E 57.81%,
    #0B091E 72.4%,
    #0B091E 84.9%,
    #0B091E 100%
  ) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  backdrop-filter: blur(20px);
}
.landingvideo {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  filter: brightness(70%);
}
@media only screen and (max-width: 600px) {
  .landingvideo {
    object-fit: cover;
    object-position: -150px;
    filter: brightness(50%);
  }
}
@media only screen and (max-width: 800px) {
  .landingvideo {
    object-fit: cover;
    object-position: -10px;
    filter: brightness(70%);
  }
}
.line2 {
  width: 120px;
  height: 2px;
  background: rgba(0, 0, 0, 0.596);
}

.heading_font {
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}
.line {
  border: 1px solid rgba(0, 0, 0, 0.749);
  width: 140px;
}
.readmorebutton {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: white;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  background-color: #0a4291;
  transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}
.readmorebutton:hover {
  background-color: #667eea;
  color: black;
  box-shadow: 0 4px 15px 0 rgba(40, 39, 139, 0.75);
}

.scroll-down {
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 50px;
  cursor: pointer;
  z-index: 3;
}
.scroll-down::before,
.scroll-down::after {
  content: "";
  position: absolute;

  top: 20%;
  left: 50%;
  height: 10px;
  width: 10px;
  transform: translate(-50%, -100%) rotate(45deg);
  border: 2px solid rgb(255, 255, 255);
  border-top: transparent;
  border-left: transparent;
  animation: scroll-down 1s ease-in-out infinite;
}
.scroll-down::before {
  top: 30%;
  animation-delay: 0.3s;
  /* animation: scroll-down 1s ease-in-out infinite; */
}

@keyframes scroll-down {
  0% {
    /* top:20%; */
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    top: 90%;
    opacity: 0;
  }
}

/* ............... */

.bubble {
  position: relative;

  padding: 0px;
  background: rgb(0, 113, 215, 0.22);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.clientlist{
  width: 100vw;
}


.sample {
  

  width: 100vw;
}
.bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: rgba(0, 113, 215, 0.3) transparent;

  display: block;
  width: 0;
  z-index: 1;
  margin-left: -15px;
  bottom: -15px;
  left: 50%;
}

/* .............. */
.slick-prev,
.slick-next {
  background: #000;
  border-radius: 15px;
  border-color: transparent;
}

.videobox {
  border-radius: 10px;
  
}
.newsimage:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/* ............ */
.wrapper {
  width: 100%;
  height: 400px;
  text-align: center;
}
.carousalclient {
  height: 300px;
}
.wrapperclient {
  width: 100%;
  height: 300px;
  text-align: center;

}

.events{
  margin-left: auto;
	margin-right: auto;
	width: 100vw;
}
.wrappere {
  overflow: hidden;
  text-align:center;
}
.carousal {
  height: 400px;
}

.slick-slide {
  margin: 10px;
}

.wrapper .slick-dots li button:before {
  font-size: 10px;
  margin-top: -200px;
  color: rgba(27, 64, 128, 0.822);
}
.nextb {
  height: 10px;
  width: 10px;
  background-color: #0a4291;
}

/* ................testimonials */
.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 5s ease-out infinite;
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.zoom-in-zoom-out1 {
  animation: zoom-in-zoom-out1 6s ease-out infinite;
}
@keyframes zoom-in-zoom-out1 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.zoom-in-zoom-out2 {
  animation: zoom-in-zoom-out 4s ease-out infinite;
}
@keyframes zoom-in-zoom-out2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.7, 0.7);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* industry solutions */
.industryImage:hover {
  transform: scale(1.1);
  transition: ease-in-out;
}

/* ..................................... */
.events{
  height: 630px;
  z-index: -1;
}
@media only screen and (max-device-width: 400px) and (min-device-width: 320px) {
  .events{
     height: 427px!important;
    
  }
}
@media only screen and (max-device-width: 480px) and (min-device-width: 400px) {
  .events{
     height: 440px!important;
    
  }
}


@media only screen and (max-device-width: 790px) and (min-device-width: 690px) {
  .events{
    height: 640px!important;
  }
}

.carousel-wrapper {
  width: 100%; 
  margin-left: auto;
  margin-right: auto;
 
}
.rec-arrow{
  color: #94DAF8!important;
  background-color: #94daf800!important;
 
  font-size: 16px !important;

  
}

.rec-dot_active{
  background-color: #94DAF8!important;
}
/* ................................. */

.profile-card {
  height: "10px";
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 5px 10px 18px #888888;
}
.profile-card .profile-img {
  filter: grayscale(100%);
  overflow: hidden;
}
.profile-card .profile-img::after {
  transform: scale(1, 1);
  transition: all 0.5s ease-in-out 0s;
}
.profile-card:hover .profile-img:after {
  transform: scale(0.9, 0.9);
  overflow: hidden;
}

.profile-card:hover img {
  transform: scale(1.02, 1.02);
  overflow: hidden;
  filter: grayscale(0%);
}
.profile-card .profile-img .over-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 10% 9%;
  text-align: left;
  background: rgba(255, 255, 255, 0.4);
  transform: scale(0, 0);
  transition: all 0.5s ease 0.5s;
}
.profile-card:hover .over-layer {
  opacity: 1;
  transform: scale(0.9, 0.9);
}

/* navbar */
.css-6e3h0h {
  background-color: transparent !important;
}

.Blue-bar {

  width: 50px;

  height: 5px;

  margin-top: 10px;

  margin-bottom: 20px;

  border-top-right-radius: 50px;

  border-bottom-right-radius: 50px;

  background-color: #0060ce;

}
/* .....................clientsection... */

@media only screen and (max-device-width: 480px) and (min-device-width: 400px) {
  .clientblueflex{
     margin-top: -145px!important;
    
  }
}
@media only screen and (max-device-width: 580px) and (min-device-width: 480px) {
  .clientblueflex{
     margin-top: -125px!important;
   
  }
}
@media only screen and (max-device-width: 690px) and (min-device-width: 580px) {
  .clientblueflex{
     margin-top: -105px!important;
    
  }
}
@media only screen and (max-device-width: 790px) and (min-device-width: 690px) {
  .clientblueflex{
     margin-top: -125px!important;  
  }
}
/* landing video*/
.mainvideoLanding{

    width: 100%;
    height: 100vh;
   
}
/* #tsparticles{
  z-index: 2;
   width: fit-content;
   height: 100%;
   position: absolute;
border: 5px solid red;

 
 
 } */
 .fontheading{
 font-family: 'Roboto ', sans-serif;
  /* font-size: 60px; */


}
.glow {

  color: #fff;
  text-align: center;
  text-shadow: 0 0 10px #5271ff, 0 0 10px #5271ff;
  /* animation: glow 1s ease-in-out infinite alternate; */
}
.videolanding{
  
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.mainborder{
border-image: linear-gradient(rgba(246, 184, 60, 0), #A9A0FF) 30;
border-width: 0.5px;
border-style: solid;
border-radius: 10px;
margin-bottom: 20px;
}


